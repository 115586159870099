<template>
  <v-container fluid class="text-center">
    <v-breadcrumbs :items="path" large></v-breadcrumbs>
    <v-toolbar-title dense dark class="title text-left mt-5 mx-5">
      <v-row>
        <v-col cols="12" md="3" xs="12"> Catálogos </v-col>
        <v-col cols="12" md="9" xs="12" class="text-end" style="white-space: normal">
          <span class="caption grey--text darken-2" style="font-family: 'Quicksand', sans-serif !important"
            >Los Catálogos solo pueden ser modificados por usuarios administradores, estarán disponibles para todos los
            usuarios y ayudan a los usuarios creadores de campañas a mantener organizados sus mensajes.</span
          >
        </v-col>
      </v-row>
    </v-toolbar-title>
    <v-card class="elevation-5 mt-5 mx-5">
      <v-card-title style="gap: 5px">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          class="mx-2"
          single-line
          hide-details
          maxlength="150"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-select
          v-model="catalogue"
          :items="list"
          item-value="value"
          item-text="text"
          dense
          label=""
          placeholder="Seleccionar catálogo..."
          class="mx-3 mt-6"
        ></v-select>
        <v-btn
          rounded
          small
          :loading="loading"
          v-show="!readOnly"
          :disabled="loading || catalogue === '' || readOnly || !isWriting"
          elevation="1"
          color="green"
          class="mx-2 custom-button white--text"
          @click="dialogs.new = true"
        >
          Agregar
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers[catalogue]"
        :items="items"
        :loading="loading"
        :search="search"
        :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
        :items_per_page="10"
        item-key="id"
        class="elevation-1"
        dense
      >
        <v-progress-linear slot="progress" color="primary" indeterminate></v-progress-linear>
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" size="22" color="primary" :disabled="loading" @click="view(item)">mdi-pencil</v-icon>
          <v-icon class="mr-2" size="22" color="error" :disabled="loading" @click="remove(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:footer.page-text="props">
          Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </v-card>
    <v-dialog v-if="catalogue !== '' && !readOnly" v-model="dialogs.new" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Nuevo registro</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formNew" lazy-validation>
              <v-row>
                <template v-for="(item, idx) in Object.keys(forms[catalogue].new)">
                  <v-col v-if="!('catalogue' in forms[catalogue].new[item])" cols="12" :key="idx">
                    <v-text-field
                      v-model="forms[catalogue].new[item].value"
                      :label="forms[catalogue].new[item].label"
                      :rules="rules[catalogue][item]"
                      :disabled="loading"
                      required
                      @keydown.enter.prevent="save()"
                      dense
                      outlined
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col v-else cols="12" :key="idx">
                    <v-autocomplete
                      v-model="forms[catalogue].new[item].value"
                      :items="catalogues[forms[catalogue].new[item].catalogue].items"
                      :item-value="catalogues[forms[catalogue].new[item].catalogue].value"
                      :item-text="catalogues[forms[catalogue].new[item].catalogue].text"
                      :label="forms[catalogue].new[item].label"
                      :rules="rules[catalogue][item]"
                      :disabled="loading"
                      required
                      @keydown.enter.prevent="save()"
                    ></v-autocomplete>
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="catalogue !== '' && !readOnly" v-model="dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Actualizar registro</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" :disabled="!isWriting" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row>
                <template v-for="(item, idx) in Object.keys(forms[catalogue].update)">
                  <v-col v-if="!('catalogue' in forms[catalogue].update[item])" cols="12" :key="idx">
                    <v-text-field
                      v-model="forms[catalogue].update[item].value"
                      :label="forms[catalogue].update[item].label"
                      :rules="rules[catalogue][item]"
                      :disabled="loading || readOnly || forms[catalogue].update[item].label === 'ID'"
                      required
                      dense
                      outlined
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col v-else cols="12" :key="idx">
                    <v-autocomplete
                      v-model="forms[catalogue].update[item].value"
                      :items="catalogues[forms[catalogue].update[item].catalogue].items"
                      :item-value="catalogues[forms[catalogue].update[item].catalogue].value"
                      :item-text="catalogues[forms[catalogue].update[item].catalogue].text"
                      :label="forms[catalogue].update[item].label"
                      :rules="rules[catalogue][item]"
                      :disabled="loading || readOnly"
                      required
                    ></v-autocomplete>
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar el registro?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()"> Cancelar </v-btn>
          <v-btn text small :loading="loading" :disabled="!isRemoving" color="error" @click="remove()">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from "@/utils/services";

export default {
  name: "catalogue",
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: "",
    error: false,
    msgError: "",
    list: [
      { text: "Categorías", value: "category" },
      { text: "Subcategorías", value: "subcategory" },
      { text: "Etiquetas", value: "label" },
    ],
    listReadOnly: [],
    catalogue: "",
    readOnly: false,
    catalogues: {},
    relations: {},
    search: "",
    path: [
      {
        text: "SMS",
        disabled: false,
        href: "home",
      },
      {
        text: "Catálogos",
        disabled: false,
        href: "",
      },
    ],
    headers: {
      category: [
        { text: "Nombre", align: "left", sortable: false, value: "name" },
        { text: "Acciones", align: "left", sortable: false, value: "actions" },
      ],
      subcategory: [
        { text: "Nombre", align: "left", sortable: false, value: "name" },
        { text: "Acciones", align: "left", sortable: false, value: "actions" },
      ],
      label: [
        { text: "Nombre", align: "left", sortable: false, value: "name" },
        { text: "Acciones", align: "left", sortable: false, value: "actions" },
      ],
    },
    items: [],
    selected: [],
    removing: null,
    forms: {
      category: {
        new: {
          name: { label: "Nombre", value: "", type: "string" },
        },
        update: {
          id_category: { label: "ID", value: null, type: "integer" },
          name: { label: "Nombre", value: "", type: "string" },
        },
      },
      subcategory: {
        new: {
          name: { label: "Nombre", value: "", type: "string" },
        },
        update: {
          id_subcategory: { label: "ID", value: null, type: "integer" },
          name: { label: "Nombre", value: "", type: "string" },
        },
      },
      label: {
        new: {
          name: { label: "Nombre", value: "", type: "string" },
        },
        update: {
          id_label: { label: "ID", value: null, type: "integer" },
          name: { label: "Nombre", value: "", type: "string" },
        },
      },
    },
    formsCopy: {},
    rules: {
      category: {
        name: [
          (v) => !!v || "El campo es requerido",
          (v) => (v && v.length <= 100) || "El campo excede la longitud máxima",
        ],
      },
      subcategory: {
        name: [
          (v) => !!v || "El campo es requerido",
          (v) => (v && v.length <= 100) || "El campo excede la longitud máxima",
        ],
      },
      label: {
        name: [
          (v) => !!v || "El campo es requerido",
          (v) => (v && v.length <= 100) || "El campo excede la longitud máxima",
        ],
      },
    },
    dialogs: {
      new: false,
      update: false,
      remove: false,
    },
  }),
  mounted() {
    if (this.isReading) {
      this.formsCopy = JSON.parse(JSON.stringify(this.forms));
      this.getCatalogues();
    }
  },
  watch: {
    catalogue: function (val) {
      if (this.isReading) {
        if (val) {
          if (this.listReadOnly.indexOf(val) >= 0) {
            this.readOnly = true;
          } else {
            this.readOnly = false;
          }
          this.refresh();
        }
      }
    },
  },
  computed: {
    isReading: function () {
      return true;
    },
    isWriting: function () {
      return true;
    },
    isRemoving: function () {
      return true;
    },
  },
  methods: {
    getCatalogues() {
      for (let key in this.catalogues) {
        this.axios.get(services.routes[key]).then((response) => {
          this.catalogues[key].items = response.data.data;
        });
      }
    },
    refresh() {
      if (this.catalogue !== "") {
        this.loading = true;
        this.axios
          .get(services.routes[this.catalogue])
          .then((response) => {
            this.items = response.data.data;
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = "Debe seleccionar un catálogo";
      }
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset();
      } else if (this.dialogs.update) {
        this.$refs.formUpdate.reset();
      }
      this.forms = JSON.parse(JSON.stringify(this.formsCopy));
      this.readOnly = false;
      this.removing = null;
      this.dialogs.new = false;
      this.dialogs.update = false;
      this.dialogs.remove = false;
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true;
        let data = {};
        for (let key in this.forms[this.catalogue].new) {
          data[key] = this.forms[this.catalogue].new[key].value;
          data[key] = this.forms[this.catalogue].new[key].type === "integer" ? parseInt(data[key]) : data[key];
        }
        this.axios
          .post(services.routes[this.catalogue], { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = "Registro guardado exitosamente";
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = "La información es incorrecta";
      }
    },
    view(data) {
      for (let key in this.forms[this.catalogue].update) {
        if (this.relations[this.catalogue] && key in this.relations[this.catalogue]) {
          this.forms[this.catalogue].update[key].value = data[key][this.relations[this.catalogue][key]];
        } else {
          this.forms[this.catalogue].update[key].value = data[key];
        }
        this.forms[this.catalogue].update[key].value =
          this.forms[this.catalogue].update[key].type === "integer" &&
          !("catalogue" in this.forms[this.catalogue].update[key])
            ? this.forms[this.catalogue].update[key].value.toString()
            : this.forms[this.catalogue].update[key].value;
      }
      this.dialogs.update = true;
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = {};
        for (let key in this.forms[this.catalogue].update) {
          data[key] = this.forms[this.catalogue].update[key].value;
          data[key] = this.forms[this.catalogue].update[key].type === "integer" ? parseInt(data[key]) : data[key];
        }
        this.axios
          .put(services.routes[this.catalogue], { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = "Registro actualizado exitosamente";
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = "La información es incorrecta";
      }
    },
    remove(data) {
      if (this.removing) {
        this.loading = true;
        this.axios
          .delete(services.routes[this.catalogue], { data: { data: this.removing } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = "Registro eliminado exitosamente";
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.removing = {};
        for (let key in this.forms[this.catalogue].update) {
          if (this.relations[this.catalogue] && key in this.relations[this.catalogue]) {
            this.removing[key] = data[key] ? data[key][this.relations[this.catalogue][key]] : null;
          } else {
            this.removing[key] = data[key];
          }
        }
        this.dialogs.remove = true;
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-family: "Quicksand", sans-serif !important;
  font-size: 2rem !important;
  line-height: 1.2;
}
</style>
